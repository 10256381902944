@import "spectre.css/dist/spectre.min.css";
@import "spectre.css/dist/spectre-icons.min.css";
@import "spectre.css/dist/spectre-exp.min.css";

@import './theme-base.scss';

// supported max-width: 1280, 1024, 800, 768, 700, 640, 320

$themes: ();
@import './theme/*.scss';
@import './colors-and-buttons.scss';
@import './profile.scss';

html,
body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin: 0;
    padding: 0;
}

body {
    flex: auto;
    @include themify($themes) {
        font: themed('font');
    }
}

#root {
    display: flex;
    flex-direction: column;
    flex: auto;
    min-height: 100%;

    & > .main {
        flex: auto;
    }
}

.main {
    display: flex;
    .content {
        flex: auto;
    }
}

h5 {
    @include themify($themes) {
        color: themed(primary-color-light);
    }
}

h4 {
    font-weight: 700;
    margin: auto 0;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar:horizontal {
    height: 9px;
}

::-webkit-scrollbar-track:horizontal {
    box-shadow: inset 0 0 6px rgba(0, 0, 169, 0.3);
}

::-webkit-scrollbar-thumb:horizontal {
    box-shadow: inset 0 0 6px rgba(0, 0, 169, 0.5);
    background-color: #00006666;
}


@keyframes highlight {
    0% {
        border-left-color: red;
    }

    100% {
        border-left-color: white;
    }
}

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

.company-logo {
    @include themify($themes) {
        width: themed(company-logo-width);
        height: themed(company-logo-height);
        margin: 1em 0.5em 1em 2em;
        cursor: pointer;
     }
}

.company-logo-loginpage{
    @include themify($themes) {
        width: 140px;
        height: 120px;
        margin: 1em 0.5em 1em 2em;
        cursor: pointer;
     }
}

.logo-text {
    cursor: pointer;
    margin: 8px 0;
}

.logo-text-primary {
    @include themify($themes) {
        color: themed(primary-color-light);
        margin: 12px 0 0 0;
        padding: 0;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 3px;
       line-height: 22px;
    }
}

.logo-text-secondary {
    @include themify($themes) {
        padding: 0;
        color: themed(secondary-color-light);
        margin: 0 0 0 6px;
        font-size: 10px;
        letter-spacing: 2px;
    }
}

.logo-text-primary-loginpage {
    @include themify($themes) {
        color: themed(primary-color-light);
        margin: 32px 0 0 0;
        padding: 0;
        font-size: 42px;
        font-weight: 500;
        letter-spacing: 6px;
        line-height: 32px;
    }
}

.logo-text-secondary-loginpage {
    @include themify($themes) {
        margin: 0;
        padding: 0;
        color: themed(secondary-color-light);
        font-size: 28px;
        letter-spacing: 4px;
    }
}

.icon-menu{
    @include themify($themes) {
        color: themed(primary-color-light);
    }
}

.menu-items {
    margin: 0.5em 1.5em 0 auto;
}

.mobile-menu{
    @include themify($themes) {
        margin: 0.8em 1em 0.5em 1em;
        color: themed(primary-color-light);
        text-align: right;
     }
}

.menu-button{
    @include themify($themes) {
        color: themed(primary-color-light);
        margin: 2px;
        text-align: right;
    }
}

.btn.btn-link:hover{
    @include themify($themes) {
        color: themed(primary-color-dark);
    }
}

/* SEARCH FIELD CSS */

.position-relative{
    position: relative;
}

.form-group .form-input {
    padding: 0 2.5rem 0 0.75rem;
    min-height: 2.7em;
    margin: 0;
}

.form-group {
    position: relative;
    .form-control-feedback {
        color: #aaa;
        position: absolute;
        top: 15px;
        right: 15px;
        pointer-events: none;
        z-index: 2;
    }
}

.notification {
    margin: 0;
    font-size: 14px;
    &.error {
        @include themify($themes) {
            color: themed(cancel-color);
        }
    }
}

.popover.popover-small .popover-container {
    width: 210px;
}
