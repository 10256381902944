.homePage {
    display: flex;
    flex-direction: column;
    flex: auto;
    height: 100%;
}

.btn-flag {
    margin: 2px;
    padding: 5px 8px;
    border: none;
    background: transparent;
    width: 45px;
    height: auto;
}

div .btn-flag:focus {
    box-shadow: none;
}

.change-lang {
    margin: 0.5em 1.5em 0 auto;
}

.display-inline-block {
    display: inline-block !important;
}

.move-left {
    text-align: right !important;
}
