@import '~common/scss/theme-base.scss';
$themes: ();
@import '~common/scss/theme/*.scss';

hr {
    opacity: 0.5;
    margin: 2em 0 0 0;
}

.manage {
    display: grid;
    grid-template-columns: 33% 32% 22% 10%;
    grid-template-rows: auto;
    gap: 15% 1%;
    grid-template-areas: 
      "title title . ."
      "active search search add-new"
      "list list list list";
}

.manage-header{
    margin-bottom: 15px;
}

.manage-title{
    grid-area: title;
}

label.form-switch{
    margin: auto 0;
    grid-area: active;
}
label.form-checkbox{
    margin: auto 0;
    grid-area: active;
}
.search-area{
    grid-area: search;
}

.add-object-button{
    margin: auto 0;
    cursor: pointer;
    grid-area: add-new;
    padding: 7px;
    align-self: end;
    border: 1px solid #686868;
}

.add-object-button:hover{
    background-color: rgb(194, 194, 194);
}

.manage-object-block {
    display: grid;
    grid-template-columns: 1fr 50%;
    grid-template-rows: 37% 1fr;
    gap: 15px;
    font-size: 15px;
    color: rgb(54, 54, 54);
    h5 {
        margin: 0 0 0.5em 0;
    }
}

.company-form-buttons{
    border-top: 1px solid rgb(184, 184, 184);
    padding-top: 5px;
    grid-column: 1/3;
}

.form-check-label{
    font-style: italic;
    margin: 0 0 0 10px;
}

.label-and-input, .label-and-input-edit {
    display: grid;
    grid-template-columns: 30% 1fr;
    margin: 3px 0;
}

.label-name {
    margin: auto 0;
    padding: 4px 0;
    font-weight: 500;
}

.datafield{
    margin: auto 0;
}

.form-check {
    margin: 0 1em;
    float: right;
}

.children{
    margin-top: 25px;
}

.deliveryData {
    @include themify($themes){
        border: 1px solid themed(primary-color-light);
        color: themed(primary-color-light);
    }
    border-radius: 15px;
    padding: 10px 1em;
    margin: 1.5em 0 0 0;
}

.delivery-opacity{
    opacity: 0.3;
}

/* Medium devices (landscape tablets, ipads, 768px and up) */
@media only screen and (max-width: 850px) {    
    .manage-object-block {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    .contact-person-block{
        grid-column: 1/2;
    }
    .invoice-address-block{
        grid-column: 1/2;
    }
    .delivery-address-block{
        grid-column: 1/2;
    }

    .label-and-input {
        grid-template-columns: 20% 1fr;
        margin: 4px 0;
    }
    .label-and-input-edit {
        grid-template-columns: 20% 1fr;
        margin: 4px 0;
    }
}

/* OBJECT LIST CSS */

.manage-list{
    display: grid;
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 5% 5%;
    padding: 5px 0;
    border-bottom: 1px solid rgb(184, 184, 184);
    p {
        margin: 0;
        padding: 0 6px;
    }
    button {
        padding: 0 6px;

    }
    i {
        margin: 0 0 3px 0;
    }
}

.manage-list-header{
    border-top: 2px solid gray;
    padding-top: 10px;
    font-weight: 700;
}

.manage-list-item{
    cursor: pointer;
}

.nothing-found{
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: gray;
    padding: 40px 0 0 0;
}

/* Medium devices (landscape tablets, ipads, 960px and up) */
@media only screen and (max-width: 960px) {    
    .manage-list{
        grid-template-columns: 30% 30% 20% 10% 10%;
    }
}

/* Extra small devices (phones / mobiles, 700px and down) */
@media only screen and (max-width: 600px) {   
    .manage-list{
        grid-template-columns: 35% 30% 15% 15%;
    }
}

//Temporary solution
.multiselect-size{
    width: 15.2rem;
}