.side-menu-company {
    margin: 0.8rem;
    .side-menu-ctitle {
        font-weight: 500;
        font-size: 24px;
        cursor: pointer;
        border-bottom: 1px solid lightgray;
        text-transform: uppercase;
        padding: 5px 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .side-menu-module {
        display: block;
        margin: 1rem 0;
        .side-menu-mtitle {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 17px;
            cursor: pointer;
            color: #3b3b3b;
            display: flex;
            flex-wrap: inherit;
            i {
                padding-right: 25px;
            }
        }
    }
    .side-menu-vtitle {
        word-break: break-all;
        cursor: pointer;
        font-size: 15px;
        list-style-type: circle;
        margin: .25rem 0 0 1.5rem;
    }
}

.company-grid {
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2%;
    row-gap: 10%;
    grid-template-rows: auto;
    text-align: center;
}

.company-grid .customer-card{
    border-radius: 15px;
}

.customer-card:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

#lblpointer {
    cursor: pointer;
}

.switch-company-list{
    display: grid;
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
    padding: 5px 0;
    border-bottom: 1px solid rgb(184, 184, 184);
    p {
        margin: 0;
        padding: 0 6px;
    }
    button {
        padding: 0 6px;

    }
    i {
        margin: 0 0 3px 0;
    }
}

.switch-company-list-header{
    border-top: 2px solid gray;
    padding-top: 10px;
    font-weight: 700;
}

.switch-company-list-item{
    cursor: pointer;
}

.switch-company-list-item:hover{
    cursor: pointer;
    background-color: rgb(228, 228, 228);
}
