@import '~common/scss/main';

#root {
    @include themify($themes) {
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        max-width: 100%;

        & > .layout {
            display: flex;
            flex-direction: column;
            flex: auto;
            align-self: stretch;
            & > .overview{
                display: flex;
                box-sizing: border-box;
                border-top: 0.5px solid lightgray;
                flex: auto;
                align-self: stretch;
                overflow: hidden;
                & > .side-menu-block {
                    align-self: stretch;
                    min-height: calc(100vh - 77px);
                    max-width: 250px;
                    min-width: 250px;
                    border-right: 0.5px solid lightgray;
                    background-color: #efefef;
                }

                & > .main-panel-block {
                    display: flex;
                    padding: 30px;
                    flex: auto;
                    align-self: stretch;
                    overflow: auto;
                    & > .content {
                        flex: auto;
                    }
                }
            }
        }
        .app-div {
            min-height: 100vh;
            background: themed(primary-color-gradient);
        }
    }
}

.outer-panel {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-page {
    background-color: #efefef;
    height: 100%;
    @include themify($themes) {
        border: 1px solid rgb(214, 214, 214);
    }
}

.panel {
    height: 100% !important;
    border: none;
    .panel-body {
        height: 100% !important;
        overflow-y: hidden;
        overflow-x: hidden;
    }
}

.login-panel {
    background-color: white;
    padding-bottom: 5em;
    box-shadow: 10px 10px 5px 0px #00476d8e;
    max-width: 700px;
    text-align: center;
}

.recover-message {
    text-align: justify;
    p:last-child {
        margin-bottom: 0;
    }
}

.recover-password {
    .forgot-password {
        cursor: pointer;
    }
    .popover-container {
        pointer-events: none;
    }
    form {
        padding-bottom: .8rem;
    }
    .requesting-recover {
        position: absolute;
        flex: auto;
        top: 0;
        left: 0;
        right: 0;
        // background-color: #00000099;
        bottom: .8rem;
        height: auto !important;
        & > .loading {
            flex: auto;
        }
    }
}

.loading-container {
    $loading-speed: 1s;
    flex: auto;
    & > .loading {
        flex: auto;
        &::after {
            animation: loading $loading-speed infinite linear;
            width: .8rem;
            height: .8rem;
            margin-left: -.4rem;
            margin-top: -.4rem;
                border-left-color: transparent;
                border-bottom-color: transparent;
                border-top-color: rgb(87, 85, 217);
                border-right-color: rgb(87, 85, 217);
        }
        &::before {
            display: block;
            animation: loading $loading-speed infinite linear;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 1.6rem;
            height: 1.6rem;
            padding: 0;
            margin-left: -.8rem;
            margin-top: -.8rem;
            background: 0 0;
            border: .1rem solid #5755d9;
                border-left-color: rgb(87, 85, 217);
                border-bottom-color: rgb(87, 85, 217);
                border-top-color: transparent;
                border-right-color: transparent;
            border-radius: 50%;
            content: "";
            opacity: 1;
            z-index: 1;
        }
        & > .gears {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: all;
            background-color: #c0c0c0dd;
            &::after {
                display: block;
                animation: loading $loading-speed infinite linear;
                position: absolute;
                left: 50%;
                top: 50%;
                width: 2.4rem;
                height: 2.4rem;
                padding: 0;
                margin-left: -1.2rem;
                margin-top: -1.2rem;
                background: 0 0;
                border: .2rem solid #5755d9;
                    border-top-color: rgb(87, 85, 217);
                    border-right-color: rgb(87, 85, 217);
                    border-left-color: transparent;
                    border-bottom-color: transparent;
                border-radius: 50%;
                content: "";
                opacity: 1;
                z-index: 1;
            }
            &::before {
                display: block;
                animation: loading $loading-speed infinite linear;
                position: absolute;
                left: 50%;
                top: 50%;
                width: 3.2rem;
                height: 3.2rem;
                padding: 0;
                margin-left: -1.6rem;
                margin-top: -1.6rem;
                background: 0 0;
                border: .2rem solid #5755d9;
                    border-top-color: transparent;
                    border-right-color: transparent;
                    border-left-color: rgb(87, 85, 217);
                    border-bottom-color: rgb(87, 85, 217);
                border-radius: 50%;
                content: "";
                opacity: 1;
                z-index: 1;
            }
        }
    }
}

.form-input {
    min-height: 2em;
    margin-top: 0.2em;
}

.login-logo {
    width: 25em;
    margin-top: 5em;
    margin-bottom: 1.5em;
}

.forgot-password {
    @include themify($themes) {
        font-size: 11px;
        color: themed(dark-neutral);
        // color: rgb(141, 141, 141);
        border: none;
        background: none;
        font-style: italic;
        text-align: center;
        &:hover {
            color: themedDarken(dark-neutral, 30%);
            font-weight: 700;
        }
    }
}

.login-button {
    @include themify($themes) {
        margin-top: 1.5em;
        .btn {
            min-height: 2.7em;
            color: white;
            background-color: themed(primary-color-light);
            font-size: .9rem;
            height: 2rem;
            padding: .35rem .6rem;
            font-weight: 700;
            &:hover{
                background-color:themed(primary-color-dark) ;
            }
        }
        .login-text{
            padding: 0 2.5em;
        }
    }
}

.full-width {
    width: 100%;
}

.change-language-flag {
    margin-top: .25em;
}
