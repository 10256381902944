.edit-view-button {
    background: none;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    size
    &:hover {
        opacity: 0.8;
    }
    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: default;
    }
    &.btn-icon {
        padding-top: 8px;
    }
}

.bottom-margin {
    margin-bottom: 15px;
}

.align-title {
    display: flex;
    flex-wrap: wrap;
    h4 {
        margin: 0;
    }
}

.card-component {
    margin-top: .4rem;
}

.component-toolbar {
    text-align: left;
    background: rgb(235, 235, 235);
    margin-bottom: 10px;
    padding: 4px 6px;
    border: gray solid 1px;

    .form-input.form-input-small {
        display: inline-block;
        text-align: center;
        width: 35px;
        background-color: #fff;
        opacity: 1;
        cursor: default;
    }

    .divider-vert {
        display: inline;
        padding: 12px 10px;
        &::before {
            border-color: black;
        }
        &.add-toolbar {
            padding-top: 20px;
        }
    }

    .form-group {
        display: inline-block;
        &.form-group-margin {
            margin: 4px 8px;
        }
        label {
            display: inline-block;
            margin-right: 4px;
        }
        .form-input {
            cursor: pointer;
            width: 52px;
            padding: 0px;
        }
    }

    &.add {
        flex-basis: 100%;
    }
}

.toolbar-title {
    font-weight: bold;
}

.bottom-tracking {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    p {
        display: inline-block;
        margin: auto 8px;
    }
}
